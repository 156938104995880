export const useSeoAndGetContent = async (route: string) => {
  const segments = route.slice(1).split("/");

  let queryRoute: string;
  let queryParams: string[] | undefined;

  queryRoute = segments[0];

  if (segments.length > 1) {
    queryParams = segments.slice(1);
  }

  const { data } = await useAsyncData(route, () => {
    if (queryParams) return queryContent(queryRoute, ...queryParams).findOne();
    return queryContent(queryRoute).findOne();
  });

  const { metaDescription, metaTitle } = data.value!;

  useSeoMeta({
    title: () => metaTitle,
    description: () => metaDescription,
    ogTitle: () => `${metaTitle} | Hirable`,
    ogDescription: () => metaDescription,
    twitterTitle: () => metaTitle,
    twitterDescription: () => metaDescription,
  });

  return data.value!;
};
